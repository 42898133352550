import React, { Suspense, lazy } from "react";
import SimpleLoader from "../Components/Loader/SimpleLoader";

// Lazy load components
const Navbar = lazy(() => import("../Components/Navbar/Navbar"));
const Dedication = lazy(() => import("../Components/Dedication/Dedication"));
const CoreComponent = lazy(() => import("../Components/CoreComponent/CoreComponent"));
const TeamUp = lazy(() => import("../Components/TeamUp/TeamUp"));
const Footer = lazy(() => import("../Components/Footer/Footer"));

const AboutUs = () => {
  return (
    <Suspense fallback={<SimpleLoader />}>
      <Navbar />
      <Dedication />
      <CoreComponent />
      <TeamUp />
      <Footer />
    </Suspense>
  );
};

export default AboutUs;
