import React from "react";
import "./Loader.css"; // Import the CSS file

const Loader = () => {
    return (
        /* From Uiverse.io by Cksunandh */
        <div className="cssloader" >
            <div className="triangle1"></div>
            <div className="triangle2"></div>
            <p className="text">Welcome to adoptimiz</p>
        </div>
    );
};

export default Loader;
