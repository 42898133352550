import React, { Suspense, lazy, useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Loader from "./Components/Loader/Loader";
import AboutUs from "./Pages/AboutUs";

// Lazy load components
const Home = lazy(() => import("./Pages/Home"));
const ContactForm = lazy(() => import("./Pages/ContactUs"));
const RequestProposal = lazy(() => import("./Pages/Publishers"));

const App = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    // Display the loader for 1.5 seconds
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 1500);

    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, []);

  // Helper to dynamically set document titles
  const DynamicTitle = () => {
    const location = useLocation();
    useEffect(() => {
      const routeTitles = {
        "/": "Home - My App",
        "/contact": "Contact Us - My App",
        "/publishers": "Publishers - My App",
        "/about": "About Us - My App",
      };
      document.title = routeTitles[location.pathname] || "Page Not Found - My App";
    }, [location]);
    return null; // No UI for this component
  };

  return (
    <>
      {showLoader ? (
        <Loader /> // Show loader globally for 1.5 seconds
      ) : (
        <Router>
          <DynamicTitle />
          <Suspense fallback={<Loader />}>
            <Routes>
              {/* Define route for the Home component */}
              <Route path="/" element={<Home />} />
              
              {/* Define route for the Contact Form component */}
              <Route path="/contact" element={<ContactForm />} />
              <Route path="/publishers" element={<RequestProposal />} />
              <Route path="/about" element={<AboutUs />} />
            </Routes>
          </Suspense>
        </Router>
      )}
    </>
  );
};

export default App;
